@import 'src/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints.scss';

.activeProspectListItem {
  background-color: $blue-light !important;

  > div {
    color: $blue !important;
  }
}

.editListContainerActive {
  @extend .activeProspectListItem;
}

.listGroupItem {
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;

  &:hover {
    @extend .activeProspectListItem;
  }

  input {
    cursor: pointer;
  }

  label {
    font-size: 16px;
    cursor: pointer;
  }
}
