@import 'variables.scss';
@import '~bootstrap/scss/mixins/breakpoints.scss';
@import '~bootstrap/scss/bootstrap.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: $font-family !important;
  background-color: $background-gray;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
button,
li {
  font-family: $font-family;
  color: $black;
  margin: 0;
}

p,
span {
  margin: 0;
}

p,
label {
  font-size: 14px;
}

a.default-link {
  color: $blue !important;
}

.hidden {
  display: none;
}

input[type='text'] {
  ::placeholder {
    color: $gray-light;
  }

  &:focus {
    outline: none;
  }
}

* {
  box-sizing: border-box;
  --webkit-scrollbar-width: 7px;
  --webkit-scrollbar-track-color: #f5f5f5;
  --webkit-scrollbar-thumb-color: #a6a6a6;
  --webkit-scrollbar-thumb-height: 2px;
  --webkit-scrollbar-thumb-width: 7px;
  --webkit-scrollbar-thumb-radius: 10px;
  --webkit-scrollbar-track-radius: 10px;
  scrollbar-color: #a6a6a6 #f5f5f5;
  scrollbar-width: 7px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-info:hover,
.btn-outline-info:active,
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:visited,
.btn-success:focus,
.btn-outline-success:hover,
.btn-outline-success:active,
.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus,
.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus,
.btn-outline-secondary:hover,
.btn-outline-success:active {
  color: $white !important;
  font-weight: 500;

  &:focus {
    outline: none;
  }
  > span {
    color: $white !important;
    font-weight: 500;
  }
}

.btn-outline-danger {
  > span {
    color: $red;
  }
}

.dropdown-item:active,
.dropdown-item.active {
  color: $white !important;
}

.dropdown-item.disabled {
  color: $gray-light !important;
}

.dropdown-menu {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 150px);
}

.alert {
  font-size: 0.75rem;
  padding: 0.75rem;
}

.form-label {
  font-weight: 500;
}

.internal-sidebar {
  flex-shrink: 0;
  height: $heightMinusNav;
  width: $sidebarWidth;
  border-right: 1px solid $gray-lightest;
  background-color: $white;
  overflow-y: auto;
  overflow-x: hidden;
}

@include media-breakpoint-down(md) {
  .internal-sidebar {
    width: 100%;
    min-height: 200px;
    max-height: none;
    border-right: none;
    border-bottom: 1px solid $gray-lightest;
    padding-bottom: 10px !important;
    overflow-y: auto !important;
  }
}

.navbar {
  user-select: none;
}

.form-check-label {
  user-select: none;
  cursor: pointer;
}

.toast {
  background-color: rgba(255, 255, 255, 0.95);
}

.popover {
  z-index: 1500;
}

@media print {
  .form-select {
    background-image: none;
    text-align: center;
  }

  .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .row {
    &.print-row-page-break-block-only {
      display: block !important;
    }
  }
}

.modal-backdrop {
  z-index: 1400;
}
.modal {
  z-index: 1500;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }

  .page-break-displayed {
    margin-top: 1rem;
    display: block !important;
    page-break-before: always;
  }
}
