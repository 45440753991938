// when updating colors or adding values here ensure they are appropriately added in src/utils/constants/scss-variables.constants.js
$white: #ffffff;
$black: #292b33;
$gray-dark: #3f4148;
$gray: #7f8085;
$gray-light: #a9aaad;
$blue-light: #e8f8ff;
$gray-lighter: #bebfc1;
$gray-lightest: #e9e9e9;
$disabled-gray: #fbfbfb;
$font-family: 'Inter', Helvetica, sans-serif;

$background-gray: #f8f8f8;
$form-border: #ced4da;

// MedScout specific - from brand guide
$dark-base: #2b313c;
$blue-gray-dark: #2c2e36;
$blue-dark: #021578;
$blue: #021b9c;
$light-blue: #2391d9;
$turquoise: #32d9c3;
$green: #2ed9c3;
$green-dark: #0bbf00;

$red: #ff4343;
$orange: #f9b57c;
$purple: #693cbd;
$pink: #cc5599;
$yellow: #23d77d;

$theme-colors: (
  'primary': $blue,
  'secondary': $purple,
  'tertiary': $turquoise,
  'info': $blue-gray-dark,
  'muted': $gray-dark,
  'success': $green-dark,
  'danger': $red,
  'dark': $gray-dark,
  'light': $gray-lightest,
);

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

$border-thin: thin solid rgb(224, 224, 224);

$navHeight: 58px;
$heightMinusNav: calc(100vh - $navHeight);
$sidebarWidth: 275px;

$zIndexMapLoadingOverlay: 3;
$zIndexMapMarkerPulse: -2;
$zIndexMapMarkerInfobox: 1;
$zIndexMapMarkerInfoboxContent: 2;
$zIndexSearchResults: 1500;
$zIndexToastContainer: 10000;
